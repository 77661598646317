import { Component } from '@angular/core';
import { EmailSettingsDto, EmailSettingsService } from '@proxy/email';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-email-settings',
  templateUrl: './email-settings.component.html',
  styleUrls: ['./email-settings.component.scss']
})
export class EmailSettingsComponent {
  emailSettings: EmailSettingsDto = {};

  constructor(private emailSettingsService: EmailSettingsService) {
  }

  ngOnInit(): void {
    this.onLoad();
  }

  async onLoad() {
    this.emailSettings = await lastValueFrom(this.emailSettingsService.getSettings());
  }

  async onSettingsSave() {
    await lastValueFrom(this.emailSettingsService.setSettingsByEmailSettings(this.emailSettings));
  }
}
