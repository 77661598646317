import { ABP, AuthService, ConfigStateService, LocalizationService, RoutesService } from '@abp/ng.core';
import { eThemeSharedRouteNames } from '@abp/ng.theme.shared';
import { AfterViewChecked, Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import * as Sentry from "@sentry/angular-ivy";
import { SettingTabsService } from '@abp/ng.setting-management/config';
import { EmailSettingsComponent } from './email-settings/email-settings.component';

@Component({
  selector: 'app-root',
  template: `
    <abp-loader-bar></abp-loader-bar>
    <app-ttb-header id="ttb-header"></app-ttb-header>
    <div id="error-container"></div>
    <abp-dynamic-layout></abp-dynamic-layout>
  `,
})
export class AppComponent implements AfterViewChecked {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    routes: RoutesService,
    private localizationService: LocalizationService,
    private configStateService: ConfigStateService,
    private settingTabs: SettingTabsService,
    private authService: AuthService
  ) {

    var ogNavigateToLogin = this.authService.navigateToLogin.bind(this.authService);
    this.authService.navigateToLogin = () =>
    {
      sessionStorage.setItem("TTB_LoginReturnPath", location.href);
      ogNavigateToLogin();
    };

    this.configStateService.getOne$('currentUser').subscribe(user => {
      if (user.isAuthenticated)
      {
        var removeTrailing = (url) => url.endsWith("/") ? url.substring(0, url.length - 1) : url;
        var clean = (url) => removeTrailing(url.split("?")[0].split("#")[0]);

        var returnTo = sessionStorage.getItem("TTB_LoginReturnPath");
        if (returnTo && clean(location.href) != clean(returnTo))
        {
          location.href = returnTo;
          sessionStorage.removeItem("TTB_LoginReturnPath");
        }
      }
    });

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.fixUpSideAndTopBars();
    });
		this.configStateService.getOne$('currentUser').subscribe(user => {
			if (user.isAuthenticated)
			{
				Sentry.setContext("userInfo", user);
			}
		});
    const newAdminConfiguration: Partial<ABP.Route> = {
      group: 'Global Setup',
      order: 7,
    };
    routes.patch(eThemeSharedRouteNames.Administration, newAdminConfiguration);

    settingTabs.remove([
      "AbpSettingManagement::Menu:Emailing",
      //"AbpIdentity::Menu:IdentityManagement",
      //"AbpAccount::Menu:Account",
      //"AbpFeatureManagement::Permission:FeatureManagement"
    ]);
    settingTabs.add([
      {
        name: "Office365 Email",
        order: 1,
        requiredPolicy: "TTB.Routing",
        component: EmailSettingsComponent
      }
    ]);
  }

  private fixUpSideAndTopBars() {
    const headerTitle = document.getElementById('ttb-header-bottom-title');
    const sidebarContainer = document.querySelector('.lpx-sidebar-container');
    const adminValues = [
      'identity',
      'openiddict',
      'language-management',
      'text-template-management',
      'audit-logs',
      'setting-management',
    ];
    let isCustomerCenter = false;
    let isCalendar = false;

    if (headerTitle) {
      if (this.router.url.includes('customers')) {
        isCustomerCenter = true;
        headerTitle.innerText = 'Customer Center';
        if (sidebarContainer) {
          sidebarContainer.classList.remove('d-block');
          sidebarContainer.classList.add('d-none');
        }
      } else if (this.router.url.includes('closing-ratio-report')) {
        headerTitle.innerText = 'Closing Ratio Report';
        if (sidebarContainer) {
          sidebarContainer.classList.remove('d-block');
          sidebarContainer.classList.add('d-none');
        }
      } else if (this.router.url.includes('client-sources')) {
        headerTitle.innerText = 'Client Sources';
        if (sidebarContainer) {
          sidebarContainer.classList.remove('d-block');
          sidebarContainer.classList.add('d-none');
        }
      } else if (adminValues.some(value => this.router.url.includes(value))) {
        headerTitle.innerText = 'Administration';
        if (sidebarContainer) {
          sidebarContainer.classList.remove('d-block');
          sidebarContainer.classList.add('d-none');
        }
      } else if (this.router.url.includes('franchises')) {
        headerTitle.innerText = 'Franchisees';
        if (sidebarContainer) {
          sidebarContainer.classList.remove('d-block');
          sidebarContainer.classList.add('d-none');
        }
      } else if (this.router.url.includes('franchisee-users')) {
        headerTitle.innerText = 'Users';
        if (sidebarContainer) {
          sidebarContainer.classList.remove('d-block');
          sidebarContainer.classList.add('d-none');
        }
      } else if (this.router.url.includes('labor-rates')) {
        headerTitle.innerText = 'Labor Rates';
        if (sidebarContainer) {
          sidebarContainer.classList.remove('d-block');
          sidebarContainer.classList.add('d-none');
        }
      } else if (this.router.url.includes('sales-tax-rates')) {
        headerTitle.innerText = 'Sales Tax Rates';
        if (sidebarContainer) {
          sidebarContainer.classList.remove('d-block');
          sidebarContainer.classList.add('d-none');
        }
      } else if (this.router.url.includes('calendar')) {
        isCalendar = true;
        headerTitle.innerText = 'Calendar';
        if (sidebarContainer) {
          sidebarContainer.classList.remove('d-block');
          sidebarContainer.classList.add('d-none');
        }
      } else if (this.router.url.includes('processing-fees')) {
        headerTitle.innerText = 'Processing Fee Rates';
        if (sidebarContainer) {
          sidebarContainer.classList.remove('d-block');
          sidebarContainer.classList.add('d-none');
        }
      } else {
        headerTitle.innerText = 'System Dashboard';
        if (sidebarContainer) {
          sidebarContainer.classList.add('d-block');
          sidebarContainer.classList.remove('d-none');
        }
      }
      if (isCustomerCenter) {
        document.getElementById('top-bar-closing-ratio-report-button').classList.remove('d-none');
        document.getElementById('top-bar-calendar-button')?.classList.remove('d-none');
        document.getElementById('top-bar-customer-center-button').classList.add('d-none');
      } else if (isCalendar) {
        document.getElementById('top-bar-closing-ratio-report-button').classList.add('d-none');
        document.getElementById('top-bar-calendar-button')?.classList.add('d-none');
        document.getElementById('top-bar-customer-center-button').classList.remove('d-none');
      } else {
        document.getElementById('top-bar-closing-ratio-report-button').classList.add('d-none');
        document.getElementById('top-bar-calendar-button')?.classList.add('d-none');
        document.getElementById('top-bar-customer-center-button').classList.add('d-none');
      }
    }
  }

  ngAfterViewChecked(): void {
    this.fixUpSideAndTopBars();
  }
}
