<h2>Office365 Email</h2>
<hr class="my-2" />

<div class="alert alert-info text-white mb-3">
    Tokens from Azure Portal have a lifetime of <strong>up to</strong> 2 years.
    Set a reminder to come back and update these tokens near to expiration.
</div>

<table>
    <colgroup>
        <col style="width: 200px;" />
        <col style="width: 500px;" />
    </colgroup>
    <tbody>

        <tr>
            <td>Default "From" Address:</td>
            <td>
                <input type="text" class="form-control" [(ngModel)]="emailSettings.from" />
            </td>
        </tr>
        
        <tr>
            <td>OAuth Application ID:</td>
            <td>
                <input type="text" class="form-control" [(ngModel)]="emailSettings.oAuthAppId" />
            </td>
        </tr>
        
        <tr>
            <td>OAuth Tenant ID:</td>
            <td>
                <input type="text" class="form-control" [(ngModel)]="emailSettings.oAuthTenantId" />
            </td>
        </tr>
        
        <tr>
            <td>OAuth Secret ID:</td>
            <td>
                <input type="text" class="form-control" [(ngModel)]="emailSettings.oAuthSecretId" />
            </td>
        </tr>
        
        <tr>
            <td>OAuth Secret Value:</td>
            <td>
                <input type="password" class="form-control" [(ngModel)]="emailSettings.oAuthSecret" />
            </td>
        </tr>

    </tbody>
</table>

<hr class="my-3" />

<button type="submit" class="btn btn-primary" (click)="onSettingsSave()">
    <i class="me-1 fa fa-save" ng-reflect-ng-class="fa fa-save"></i> Save
</button>