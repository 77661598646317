import { CoreModule } from '@abp/ng.core';
import { GdprConfigModule } from '@volo/abp.ng.gdpr/config';
import { SettingManagementConfigModule } from '@abp/ng.setting-management/config';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { ErrorHandler, InjectionToken, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommercialUiConfigModule } from '@volo/abp.commercial.ng.ui/config';
import { AccountAdminConfigModule } from '@volo/abp.ng.account/admin/config';
import { AccountPublicConfigModule } from '@volo/abp.ng.account/public/config';
import { AuditLoggingConfigModule } from '@volo/abp.ng.audit-logging/config';
import { IdentityConfigModule } from '@volo/abp.ng.identity/config';
import { LanguageManagementConfigModule } from '@volo/abp.ng.language-management/config';
import { registerLocale } from '@volo/abp.ng.language-management/locale';
//import { SaasConfigModule } from '@volo/abp.ng.saas/config';
import { TextTemplateManagementConfigModule } from '@volo/abp.ng.text-template-management/config';
import { HttpErrorComponent, ThemeLeptonXModule } from '@volosoft/abp.ng.theme.lepton-x';
import { SideMenuLayoutModule } from '@volosoft/abp.ng.theme.lepton-x/layouts';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { APP_ROUTE_PROVIDER } from './route.provider';
import { OpeniddictproConfigModule } from '@volo/abp.ng.openiddictpro/config';
import { FeatureManagementModule } from '@abp/ng.feature-management';
import { AbpOAuthModule } from '@abp/ng.oauth';
import { FRANCHISES_FRANCHISE_ROUTE_PROVIDER } from './franchises/franchise/providers/franchise-route.provider';
import { SALES_TAX_RATES_SALES_TAX_RATE_ROUTE_PROVIDER } from './sales-tax-rates/sales-tax-rate/providers/sales-tax-rate-route.provider';
import { CLIENT_SOURCES_CLIENT_SOURCE_ROUTE_PROVIDER } from './client-sources/client-source/providers/client-source-route.provider';
import { LABOR_RATES_LABOR_RATE_ROUTE_PROVIDER } from './labor-rates/labor-rate/providers/labor-rate-route.provider';
import { CUSTOMERS_CUSTOMER_ROUTE_PROVIDER } from './customers/customer/providers/customer-route.provider';
import { FRANCHISEE_USERS_FRANCHISEE_USER_ROUTE_PROVIDER } from './franchisee-users/franchisee-user/providers/franchisee-user-route.provider';
import { PROCESSING_FEES_PROCESSING_FEE_ROUTE_PROVIDER } from './processing-fees/processing-fee/providers/processing-fee-route.provider';
import { JOB_TEMPLATES_JOB_TEMPLATE_ROUTE_PROVIDER } from './job-templates/job-template/providers/job-template-route.provider';
import { OTHERS_GROUP } from '@abp/ng.core';
import { SharedModule } from './shared/shared.module';
import { CLOSING_RATIO_REPORT_ROUTE_PROVIDER } from './closing-ratio-report/providers/closing-ratio-report-route.provider';
import { TtbHeaderComponent } from './ttb-header/ttb-header.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorsManagementService } from './shared/http-errors/errors-management.service';
import { CALENDAR_ROUTE_PROVIDER } from './calendar/providers/calendar-route.provider';
import { CustomErrorHandler } from 'src/errorHandler';
import { EmailSettingsComponent } from './email-settings/email-settings.component';

@NgModule({
  declarations: [AppComponent, TtbHeaderComponent, EmailSettingsComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    CoreModule.forRoot({
      environment,
      registerLocaleFn: registerLocale(),
    }),
    AbpOAuthModule.forRoot(),
    ThemeSharedModule.forRoot({
      httpErrorConfig: {
        errorScreen: {
          component: HttpErrorComponent,
          forWhichErrors: [401, 403, 404, 500],
          hideCloseIcon: true,
        },
      },
    }),
    AccountAdminConfigModule.forRoot(),
    AccountPublicConfigModule.forRoot(),
    IdentityConfigModule.forRoot(),
    LanguageManagementConfigModule.forRoot(),
    // SaasConfigModule.forRoot(),
    AuditLoggingConfigModule.forRoot(),
    OpeniddictproConfigModule.forRoot(),
    TextTemplateManagementConfigModule.forRoot(),
    SettingManagementConfigModule.forRoot(),
    ThemeLeptonXModule.forRoot(),
    SideMenuLayoutModule.forRoot(),
    CommercialUiConfigModule.forRoot(),
    FeatureManagementModule.forRoot(),
    GdprConfigModule.forRoot(),
    GdprConfigModule.forRoot({
      cookieConsent: {
        privacyPolicyUrl: 'gdpr-cookie-consent/privacy',
        cookiePolicyUrl: 'gdpr-cookie-consent/cookie',
      },
    }),
  ],
  providers: [
    APP_ROUTE_PROVIDER,
    {
      provide: OTHERS_GROUP,
      useValue: 'ModuleName::MyOthersGroupKey',
    },
    FRANCHISES_FRANCHISE_ROUTE_PROVIDER,
    SALES_TAX_RATES_SALES_TAX_RATE_ROUTE_PROVIDER,
    CLIENT_SOURCES_CLIENT_SOURCE_ROUTE_PROVIDER,
    LABOR_RATES_LABOR_RATE_ROUTE_PROVIDER,
    CUSTOMERS_CUSTOMER_ROUTE_PROVIDER,
    FRANCHISEE_USERS_FRANCHISEE_USER_ROUTE_PROVIDER,
    PROCESSING_FEES_PROCESSING_FEE_ROUTE_PROVIDER,
    JOB_TEMPLATES_JOB_TEMPLATE_ROUTE_PROVIDER,
    CLOSING_RATIO_REPORT_ROUTE_PROVIDER,
    CALENDAR_ROUTE_PROVIDER,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorsManagementService, multi: true },
    { provide: ErrorHandler, useValue: new CustomErrorHandler({
			showDialog: false,
			logErrors: true
			})
		},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
